import axiosClient from '@/api/axiosInstance'
import { USER } from '@/api/endpoints'
import axios, { AxiosResponse } from 'axios'
import { FetchUsesrsResponse, UpdateUserInfo, UserInfo } from '../types/user.types'
import { TableParams } from '@/modules/core/types/core.types'

/**
 * Fetches a list of users from the system.
 * @param params Optional search term and page number for filtering and pagination.
 * @returns A list of users.
 */
export const fetchUser = async (params?: TableParams): Promise<FetchUsesrsResponse> => {
  const api = axiosClient()
  try {
    const response: AxiosResponse<FetchUsesrsResponse> = await api.get<FetchUsesrsResponse>(
      USER.USER_LIST,
      { params: params ?? {} }
    )

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      console.error(error)
      return {
        count: 0,
        results: [],
        next: '',
        previous: ''
      }
    }
    throw new Error('Failed to fetch user data')
  }
}

/**
 * Fetches the user profile information.
 * @param {string} id - The user ID for which to fetch the profile
 * @returns {Promise<AxiosResponse<UserInfo>>} The user profile data.
 */
export const getUserInfo = async (id: string): Promise<AxiosResponse<UserInfo>> => {
  const api = axiosClient()
  const response = await api.get(USER.profile(id))
  return response
}

/**
 * Updates a user's information by ID.
 * @param id - The ID of the user to update.
 * @param payload - The new data to update for the user.
 * @returns The updated user data.
 */
export const updateUserData = async (
  id: string,
  payload: UpdateUserInfo
): Promise<AxiosResponse<UpdateUserInfo>> => {
  const api = axiosClient()
  const response = await api.put(USER.updateUserInfo(id), payload)
  return response
}
