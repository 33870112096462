import React, { ReactNode, useEffect, useState } from 'react'
import { Layout, Grid, Breadcrumb, theme } from 'antd'
import { Outlet, useLocation, Link } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Topbar from '../components/Topbar'
import { useTranslation } from 'react-i18next'

const { Content } = Layout
const { useBreakpoint } = Grid

interface DashboardLayoutProps {
  children?: ReactNode
}

/**
 * Main dashboard layout component
 * @param {Object} props Component props
 * @param props.children
 * @returns {React.ReactElement} Dashboard layout with sidebar and content area
 */
function DashboardLayout({ children }: DashboardLayoutProps): React.ReactElement {
  const { t } = useTranslation(['task', 'auth', 'company', 'season', 'user'])

  // Detect screen size
  const screens = useBreakpoint()
  const isMobile = !screens.lg // true if width < 992px

  // Collapsed state in the layout - default to true (closed) on mobile, false (open) on large screens
  const [collapsed, setCollapsed] = useState(isMobile)

  // Toggle function passed down to children (Topbar, Sidebar)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(isMobile)
  }, [isMobile])

  // to set current path
  const location = useLocation()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.resolvedLanguage ?? 'ar'

  const {
    token: { colorTextBase, colorTextDisabled }
  } = theme.useToken()

  // Type definition for breadcrumbItems
  interface BreadcrumbItem {
    title: string
    href?: string
  }

  // Define BREADCRUMBS structure
  const BREADCRUMBS = {
    USERS: {
      LIST: { title: t('manageUser', { ns: 'user' }), href: 'users' },
      Add: { title: t('addNewUser', { ns: 'user' }), href: 'users/register' },
      SHOW: { title: t('userInfo', { ns: 'user' }), href: '#' },
      EDIT: { title: t('editUserInfo', { ns: 'user' }), href: '#' }
    },
    COMPANY: {
      LIST: { title: t('companies', { ns: 'company' }), href: 'company' },
      SHOW: { title: t('CompanyInfo', { ns: 'company' }), href: '#' },
      UPDATE: { title: t('edit_company', { ns: 'company' }), href: '#' }
    },
    TASKS: {
      LIST: { title: t('tasks', { ns: 'task' }), href: 'tasks' },
      DETAILS: (taskId: string) => ({
        title: t('task_name', { id: taskId }),
        href: `tasks/${taskId}`
      })
    },
    Seasons: {
      LIST: { title: t('seasons', { ns: 'season' }), href: 'seasons' },
      ADD: { title: t('addNewSeason', { ns: 'season' }), href: 'seasons/add-season' },
      ASSIGN: { title: t('Assign.assignSeason', { ns: 'season' }), href: 'seasons/assign-season' }
    },
    Plans: {
      LIST: { title: t('plans', { ns: 'plan' }), href: 'plans' },
      ADD: { title: t('addPlan', { ns: 'plan' }), href: 'plans/add-plan' },
      UPDATE: (planId: string) => ({
        title: t('updatePlan', { id: planId, ns: 'plan' }),
        href: `plans/update-plan/${planId}`
      })
    },
    TasksTracking: {
      LIST: { title: t('tasksTracking', { ns: 'task' }), href: 'tasks-tracking' },
      DETAILS: { title: t('companyTask', { ns: 'task' }), href: '/tasks-tracking/compnay-task/' }
    },
    TaskDetails: {
      LIST: { title: t('tasksTracking', { ns: 'task' }), href: 'tasks-tracking' },
      DETAILS: (taskId: string) => ({
        title: t('task', { ns: 'task' }),
        href: `task-tracking/${taskId}`
      })
    }
  }

  const getBreadcrumbs = (pathname: string): BreadcrumbItem[] => {
    const result: BreadcrumbItem[] = []
    if (pathname.includes('/users')) {
      result.push(BREADCRUMBS.USERS.LIST)
      if (pathname.includes('/register')) {
        result.push(BREADCRUMBS.USERS.Add)
      } else if (pathname.includes('/profile/retrieve-info/')) {
        result.push(BREADCRUMBS.USERS.SHOW)
      } else if (pathname.includes('/profile/update/')) {
        result.push(BREADCRUMBS.USERS.EDIT)
      }
    } else if (pathname.includes('/seasons')) {
      result.push(BREADCRUMBS.Seasons.LIST)
      if (pathname.includes('/add')) {
        result.push(BREADCRUMBS.Seasons.ADD)
      } else if (pathname.includes('/assign')) result.push(BREADCRUMBS.Seasons.ASSIGN)
    } else if (pathname.includes('/company')) {
      result.push(BREADCRUMBS.COMPANY.LIST)
      if (pathname.includes('/company-info')) result.push(BREADCRUMBS.COMPANY.SHOW)
      else if (pathname.includes('/update')) {
        result.push(BREADCRUMBS.COMPANY.UPDATE)
      }
    } else if (pathname.includes('/tasks-tracking')) {
      result.push(BREADCRUMBS.TasksTracking.LIST)
      if (pathname.includes('/compnay-task')) result.push(BREADCRUMBS.TasksTracking.DETAILS)
    } else if (pathname.includes('/task-tracking')) {
      result.push(BREADCRUMBS.TaskDetails.LIST)
      const taskId = pathname.split('/').pop()
      if (taskId && !isNaN(Number(taskId))) {
        result.push(BREADCRUMBS.TaskDetails.DETAILS(taskId))
      }
    }
    // Added logic for Plans routes
    else if (pathname.includes('/plans')) {
      result.push(BREADCRUMBS.Plans.LIST)
      if (pathname.includes('/add-plan')) {
        result.push(BREADCRUMBS.Plans.ADD)
      } else if (pathname.includes('/update-plan')) {
        const planId = pathname.split('/')[3] // Extract planId from /plans/update-plan/[planId]
        if (planId) {
          result.push(BREADCRUMBS.Plans.UPDATE(planId))
        }
      }
    }
    return result
  }

  const breadcrumbItems: BreadcrumbItem[] = getBreadcrumbs(location.pathname)

  const userType = localStorage.getItem('USER_TYPE')
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Sidebar
        isMobile={isMobile}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        userRole={userType}
      />
      <Layout>
        <Topbar isMobile={isMobile} collapsed={collapsed} onToggleSidebar={toggleCollapsed} />
        <Content className="rounded-md m-4">
          {breadcrumbItems.length > 0 && (
            <Breadcrumb
              separator={<span style={{ color: colorTextDisabled, fontWeight: 500 }}>{' > '}</span>}
            >
              {breadcrumbItems.map((item, index) => {
                const isLast = index === breadcrumbItems.length - 1

                return (
                  <Breadcrumb.Item key={index}>
                    {isLast ? (
                      <span style={{ color: colorTextBase, fontWeight: 500 }}>{item.title}</span>
                    ) : (
                      <Link
                        to={`/${currentLanguage}/${item.href ?? '#'}`}
                        style={{ color: colorTextDisabled, fontWeight: 500 }}
                      >
                        {item.title}
                      </Link>
                    )}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          )}
          <div className="p-6 min-h-[360px]">{children ?? <Outlet />}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
